// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAjws_z1SB-5bCyoFqT7w6bTFet7BAz9wA",
  authDomain: "pentachat-d83b0.firebaseapp.com",
  projectId: "pentachat-d83b0",
  storageBucket: "pentachat-d83b0.appspot.com",
  messagingSenderId: "532088366999",
  appId: "1:532088366999:web:bffc230141a119c88f06d2"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

export { auth, firestore, storage };
