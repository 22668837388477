// src/Chatroom.js
import React, { useState, useEffect, useRef } from 'react';
import { auth, firestore, storage } from './firebase';
import { collection, addDoc, query, orderBy, onSnapshot } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import './Chatroom.css';
import logo from './logo.png';

const Chatroom = () => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const user = auth.currentUser;
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (!user) {
      navigate('/Login');
      return;
    }

    const q = query(collection(firestore, 'messages'), orderBy('timestamp'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messages = querySnapshot.docs.map(doc => doc.data());
      setMessages(messages);
    });

    return () => unsubscribe();
  }, [user, navigate]);

  useEffect(() => {
    // Scroll to bottom whenever messages change
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (message.trim() === '' && !image) return;

    let imageUrl = null;
    if (image) {
      const imageRef = ref(storage, `images/${image.name}`);
      await uploadBytes(imageRef, image);
      imageUrl = await getDownloadURL(imageRef);
      setImage(null);
    }

    const username = user.email.split('@')[0];
    await addDoc(collection(firestore, 'messages'), {
      text: message,
      username,
      imageUrl,
      timestamp: new Date(),
    });

    setMessage('');
  };

  return (
    <div className="chatroom">
      <div className="head">
        <img src={logo} alt="Logo" className="logo" />
        {/*<h2>Pentachat</h2>*/}
      </div>

      <div className="messages">
        {messages.map((msg, index) => (
          <div key={index} className="message">
            <p><strong>{msg.username} &gt;&gt; </strong>{msg.text}</p>
            {msg.imageUrl && <img src={msg.imageUrl} alt="uploaded" className="message-image" />}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      <div className="UserInput">
        <form onSubmit={handleSendMessage} className="input-form">
          <input
            type="text"
            placeholder="Nachricht eingeben"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="message-input"
          />
          <input
            type="file"
            onChange={(e) => setImage(e.target.files[0])}
            className="file-input"
          />
          <input
            type="text"
            value={image ? image.name : ''}
            readOnly
            className="filename-input"
          />
          <button type="submit" className="send-button">Senden</button>
        </form>
      </div>
    </div>
  );
};

export default Chatroom;
